import { useModalContext } from "@ally/metronome-ui";
import { useOktaAuth } from '@okta/okta-react';
import { useAtom } from "jotai";
import useFlags from "launchdarkly-react-client-sdk/lib/useFlags";
import React from "react";
import { useLocation } from "react-router";
import { DealershipModal } from "../../AppStyled";
import { useGetDealerships } from "../../hooks/dealershipHooks/useGetDealerships";
import {
  useDealershipInfo,
} from "../../hooks/useDealershipInfo";
import { noMatchRoute } from "../../routes/NoMatchPage";
import { ChangeDealershipModal } from "../Modal/ChangeDealershipModal";
import { ChangeDealershipStyle } from "./HeaderStyled";

const ChangeDealershipModalTrigger: React.FC = () => {
  const { setOpen } = useModalContext();
  return (
    <ChangeDealershipStyle id="change-dealership-link" onClick={() => setOpen(true)} tabIndex={0} >
      Change Dealership
    </ChangeDealershipStyle>
  );
};

const DealershipHeader: React.FC = () => {
  const { authState } = useOktaAuth();
  const secureRoutesEnabled = process.env.REACT_APP_SECURE_ROUTES === 'true';
  const { pathname } = useLocation();
  const { data: dealerships } = useGetDealerships();
  const dropDownOptions = dealerships?.map(d => {
    return {
      value: d.dealershipNumber,
      label: `${d.dealershipNumber} ${d.name}`
    };
  });
  const { ffMaintenanceMode } = useFlags();

  const useNoMatchRoute = useAtom(noMatchRoute);

  const dealerFirstName = secureRoutesEnabled
    ? authState?.accessToken?.claims?.firstName.toString()
    : "Test";

  const dealerLastName = secureRoutesEnabled
    ? authState?.accessToken?.claims?.lastName.toString()
    : "User";

  const [dealershipInfo] = useDealershipInfo();
  const lastLogin = authState?.accessToken?.claims?.auth_time ?? Date.now()/1000;

  function formatDate(timestamp: number): string {
    let date = (timestamp) ? new Date(timestamp*1000) : new Date();
    return Intl.DateTimeFormat("en-US", {
      timeZone: "America/New_York",
      year: "numeric",
      month: "short",
      day: "numeric",
    }).format(date);
  }
  
  function formatTime(timestamp: number): string {
    let date = (timestamp) ? new Date(timestamp*1000) : new Date();

    return Intl.DateTimeFormat("en-US", {
      timeZone: "America/New_York",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    }).format(date) + " ET";
  }


  if(pathname.match("^\\/dealership\\/?[0-9]+\\/customer\\/?[0-9]+\\/delivery-group\\/?[0-9]+\\/summary") || pathname.match("/not-supported") || useNoMatchRoute[0] || ffMaintenanceMode || pathname.match("/server-error"))
  {
    return (
      <></>
    )
  } else if ((secureRoutesEnabled && !authState?.accessToken) || !dealershipInfo?.dealershipName) {
    return <>Loading...</>
  } 
  
  
  return (
    <>
      Hello {dealerFirstName} <span data-private>{dealerLastName}</span> of {dealershipInfo.dealershipName}{" "}
      {dealerships?.length > 1 && (
        <DealershipModal
          trigger={<ChangeDealershipModalTrigger />}
          content={<ChangeDealershipModal options={dropDownOptions} />}
          fullScreenMd={false}
          disableFullScreenSmDown={true}
          width="md"
        />
      )}
      <br />
      Last Log In {formatDate(lastLogin!)} • {formatTime(lastLogin!)}
    </>
  );
}

export default DealershipHeader;
