import { useMutation } from "@tanstack/react-query";
import { useOktaAuth } from "@okta/okta-react";

async function uploadFileToDocService(token: string, signal: AbortSignal, inputtedFile: File, dealershipNumber: string, deliveryGroupNumber: string) {
    const base_url = process.env.REACT_APP_MULTI_ASSET_API_BASE_URL;
    try {
        const docServiceEndpoint = `${base_url}/docservice/uploadDocument`;
            const data = new FormData();
            data.append('file', inputtedFile, inputtedFile.name);
            const docUploadRequest = {
                createdBy: "CSG FleetLine",
                uploadSource: "CSG-FLEETLINE",
                description: "Upload to DFS S3 bucket under staged_files",
                fileName: inputtedFile.name,
                dealershipNumber: dealershipNumber,
                deliveryGroupNumber: deliveryGroupNumber
            }
            const jsonFile = new Blob([JSON.stringify(docUploadRequest)], {
                type: "application/json",
            })
            data.append('documentUploadRequest', jsonFile, 'CSG-FLEETLINE-S3.json');
        const options = {
            method: "POST",
                signal: signal,
            body: data,
            headers: {
                "Authorization": token
            }
        }
        await fetch(docServiceEndpoint, options); 
    } catch (error) {
        console.log('error in uploading file: ', error)
    }

};

type UploadFiles = {
    signal: AbortSignal;
    file: File;
}

export function useUploadStagedFiles(dealershipNumber: string, deliveryGroupNumber: string) {
    const { authState } = useOktaAuth();
    const token = authState?.accessToken?.accessToken ?? "hereForTestsWithoutAUthEnabled";

    return useMutation(
        ['upload-file'],
        async (req: UploadFiles) => await uploadFileToDocService(token, req.signal, req.file, dealershipNumber, deliveryGroupNumber),
        { retry: 2 }
    )
}

