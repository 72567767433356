import { useDealershipInfo } from "../../../../hooks/useDealershipInfo";
import { useParams } from "react-router";
import { useContext } from "react";
import { ContextType, TabContext } from "../../TabContext";
import { useGetDeliveryGroups } from "../../../../hooks/deliveryGroupHooks/useGetDeliveryGroups";
import { BoxStyled } from "../../../Dashboards/SingleCustomerDashboard/VehicleDashboard/SelectDeliveryStyles";
import { Identifiable } from "../../../../model/identifiable";
import { useHistory } from "react-router";
import { DropdownMenu } from "@ally/metronome-ui";
import { ActiveTabs } from "../../../../constants/enums";

const DeliveryGroupDropDown: React.FC<{
  type: string;
  selectedVehicles?: Identifiable[];
  close?: () => void;
  setActiveDeliveryGroup?: React.Dispatch<React.SetStateAction<Object>>;
  setIsLoadingAddGroup?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowGroupWarning?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  type,
  selectedVehicles,
  close,
  setActiveDeliveryGroup,
  setIsLoadingAddGroup,
  setShowGroupWarning,
}) => {
  const [dealershipInfo] = useDealershipInfo();
  const dealershipNumber = dealershipInfo?.dealershipNumber;
  const { id: customerId } = useParams() as any;
  const {
    saveActiveDeliveryGroupId,
    saveActiveDeliveryGroupName: setDgName,
    activeDeliveryGroupName: dgName,
    saveVehicleIdsToAddToDg,
    setActiveTabTo,
    saveSelection
  } = useContext(TabContext) as ContextType;
  const history = useHistory();
  const setDgId = saveActiveDeliveryGroupId;
  const { data: list } = useGetDeliveryGroups(
    dealershipNumber,
    customerId
  );

  const createGroup = () => {
    const params = new URLSearchParams();
    params.append("action", "create-group");

    history.push({ search: params.toString() });
    saveVehicleIdsToAddToDg(selectedVehicles ?? []);
    setActiveTabTo(1);
    if (type === "ALL") {
      saveSelection({
        destinationTab: ActiveTabs.DELIVERY_GROUPS,
      });
    }
    if (close) {
      close();
    }
  };

  const handleClick = (event: any, context?: any) => {
    let dgID: string = event.id;
    let dgName: string = event.group_name;
    //setDestination(true)
    // truncate at 25 characters
    const DG_NAME_MAX_CHARS = 25;
    if (dgName.length > DG_NAME_MAX_CHARS) {
      dgName = dgName.slice(0, DG_NAME_MAX_CHARS) + "...";
    }
    if (type === "ALL") {
    setDgId(dgID);
      setDgName(dgName);
      context?.closeMenu(); 
    } else if (type === "OPEN_ONLY") {
      setIsLoadingAddGroup(false);
      setShowGroupWarning(false);
      setActiveDeliveryGroup({ id: dgID, name: dgName });
    }
  };

  let deliveryGroupList;

  if (type === "OPEN_ONLY") {
    deliveryGroupList = list.filter(
      (deliveryGroup) => deliveryGroup.delivery_status === "OPEN"
    );
    deliveryGroupList = deliveryGroupList.sort(
      (a, b) => b.last_updated.getTime() - a.last_updated.getTime()
    );
  } else if (type === "ALL") {
    // Sort delivery groups last by funding confirmed status then by activity date
    deliveryGroupList = list?.sort((a, b) => {
      if (a.delivery_status !== "FUNDING_CONFIRMED" && b.delivery_status === "FUNDING_CONFIRMED") {
        return -1;
      }

      else if (b.delivery_status !== "FUNDING_CONFIRMED" && a.delivery_status === "FUNDING_CONFIRMED") {
        return 1;
      }

      else {
        return b.last_updated.getTime() - a.last_updated.getTime();
      }
    });
  };

  const returnItems = (groups) => {
    const items = [{
      text: "+ Create Group",
      onClick: () => { createGroup() },
    }];
    groups.map((group: any) => 
      items.unshift({
        text: group.group_name,
        onClick: () => {
          handleClick(group)
        }
      })
    )
    return items;
  };
  const dropDownList = returnItems(deliveryGroupList);

  const ariaLabelMessage = dgName || "Select group";

  return (
    <BoxStyled>
      <DropdownMenu
        dropdownItems={dropDownList}
        title={type === "OPEN_ONLY" ? "Select Delivery Group" : ariaLabelMessage}
        selectionAsTitle={true}
        placement="bottom-start"
        ariaLabelTrigger={(isOpen): string =>
          isOpen
            ? `Close ${ariaLabelMessage} menu`
            : `Open ${ariaLabelMessage} Menu`
        }
      />
    </BoxStyled>
  );
};

export default DeliveryGroupDropDown;
