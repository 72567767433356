import React, { useContext, useEffect, useRef, useState } from "react";
import DeliveryGroupHeader from "./DeliveryGroupHeaders/DeliveryGroupHeader";
import ContractedDeliveryGroupDetailsNew from "./DeliveryGroupDetails/ContractedDeliveryGroupDetailsNew";
import { useDealershipInfo } from "../../../hooks/useDealershipInfo";
import { TabContext, ContextType } from "../TabContext";
import { useParams } from "react-router";
import { ContractProgressTracker } from "../../ProgressTracker/ContractProgressTracker";
import {
  Container,
  StyledIcon,
  CardSelectionCardStyle,
  CardSelectionGroupStyle,
  ContractError,
  SigningOptionsButton
} from "./SigningOptionsStyled";
import { muiDocumentPage } from "@ally/metronome-icons";
import { Link } from "@ally/metronome-ui";
import { contractSummaryRoute } from "../../../routes/RouteFactory";
import { ContractActions } from "../../Contracts/ContractActions";
import VerifyEmails from "./VerifyEmails";
import { ReadDeliveryGroup } from "../../../model/delivery-group.model";
import { useSelectSigningOption } from "../../../hooks/docusignHooks/useSelectSigningOption";
import { EditContractModal } from "../../Modal/EditContractModal";
import { useAnalytics } from "../../../hooks/analytics/useAnalytics";
import { PageViews, emitPageViewEvent } from "../../../analytics/pageViews";
import { emitCustomEvent } from "../../../analytics/customEvent";

type SigningOptionsProps = {
  contractStatus?: "SUCCESS" | "PENDING" | "ERROR";
  isActiveTab?: boolean;
  finalizedPage?: boolean;
  deliveryGroup?: ReadDeliveryGroup;
};

export enum RadioAction {
  eSign = "e-sign",
  addDocument = "add-document",
  printAndSign = "print-sign",
}

const SigningOptions: React.FC<SigningOptionsProps> = ({
  contractStatus, isActiveTab, deliveryGroup,
}) => {
  const analytics = useAnalytics();
  const [dealershipInfo] = useDealershipInfo();
  const { dealershipNumber } = dealershipInfo || {};
  const { id: customerId } = useParams<{ id: string }>();
  const checkBoxOptions = useRef("");
  const [displayEmails, setDisplayEmails] = useState(true);
  const [displaySendContract, setSendContract] = useState(false);
  const [isSigningOptionLoading, setSigningOptionLoading] = useState(false);
  const [error, setError] = useState("");

  const {
    saveActiveDeliveryGroupName,
    activeDeliveryGroupId,
  } = useContext(TabContext) as ContextType;

  const selectSigningOption = useSelectSigningOption(dealershipNumber, activeDeliveryGroupId);

  const progressTracker = (
    <ContractProgressTracker stepNumber={2} />
  );

  const handleNext = () => {
    let onSuccess = () => { }
    let onError = (errs: string[]) => {
      console.warn(errs)
    };

    if (contractStatus !== "SUCCESS") {
      setError("The contract must be completely generated before selecting a signing option.")
    }
    
    else if (checkBoxOptions.current !== "") {
      const customEventMap = {
        [RadioAction.eSign]: "E-Sign with DocuSign",
        [RadioAction.addDocument]: "Add Documents and E-Sign with DocuSign",
        [RadioAction.printAndSign]: "Print and Sign"
      };
      
      analytics(emitCustomEvent, {
        name: "signingSelection",
        type: "userInteraction",
        attr: {
          cardSelected: customEventMap[checkBoxOptions.current]
        }
      });

      selectSigningOption.mutate({ onSuccess, onError, signingOption: checkBoxOptions.current });
      setSigningOptionLoading(true);
    }
    else {
      console.error(`The option '${checkBoxOptions.current}' is not handled`)
    }
  }

  const onCardSelect = (cardId) => {
    checkBoxOptions.current = cardId;
    (cardId === RadioAction.printAndSign) ? setDisplayEmails(false) : setDisplayEmails(true);
    (cardId === RadioAction.eSign) ? setSendContract(true) : setSendContract(false);
  }

  useEffect(() => {
    if(isActiveTab){
      analytics(emitPageViewEvent, PageViews.DELIVERY_GROUP_CONTRACT_FINALIZE);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (contractStatus === "SUCCESS") {
      setError("");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveTab, displaySendContract, isSigningOptionLoading, contractStatus])

  return (
    <>
      <DeliveryGroupHeader />
      <ContractedDeliveryGroupDetailsNew
        progressTracker={progressTracker}
        updateDeliveryGroupName={saveActiveDeliveryGroupName}
        deliveryGroupStatus={"CONTRACT_CREATED"}
      />
      <Container>
        <h3>Review documents</h3>
        <div className="summary">
          <StyledIcon icon={muiDocumentPage} size="lg" ariaHidden />
          <Link
            aria-label="Contract summary"
            text="Contract summary"
            href={contractSummaryRoute(
              dealershipNumber,
              customerId,
              activeDeliveryGroupId
            )}
            variant="link"
            target="_blank"
            allytmln="open-contract-summary-page"
          />
        </div>
        <div className="contract">
          <StyledIcon icon={muiDocumentPage} size="lg" ariaHidden />
          <ContractActions
            activeDeliveryGroupId={activeDeliveryGroupId}
            contractStatus={contractStatus}
          />
        </div>
        <div>
          <h3>Choose signature method</h3>
          <h4>Choose a method to sign and submit your required documents:</h4>
          <CardSelectionGroupStyle 
            id="signing-options" align="inline" variant="single-select" aria-label="Signing options"
            inline={{ cardWidth: "294px", cardHeight:"169px", cardAlignment:"center"}} 
            onSelection={cardId => onCardSelect(cardId)} defaultSelection={RadioAction.eSign}
            emails={displayEmails ? "true" : "false"}>
            <CardSelectionCardStyle
              cardId={RadioAction.eSign}
              aria-label={RadioAction.eSign}
              ariaLabelDescription={RadioAction.eSign}
              title="E-Sign with DocuSign"
              description="Instantly send your completed contract to your customer as is and request signatures.">
            </CardSelectionCardStyle>
            <CardSelectionCardStyle
              cardId={RadioAction.addDocument}
              aria-label={RadioAction.addDocument}
              ariaLabelDescription={RadioAction.addDocument}
              title="Add documents and E-Sign with DocuSign"
              description="Add additional documents to the DocuSign package for customer review and request signatures through DocuSign.">
            </CardSelectionCardStyle>
            <CardSelectionCardStyle
              cardId={RadioAction.printAndSign}
              aria-label={RadioAction.printAndSign}
              ariaLabelDescription={RadioAction.printAndSign}
              title="Print and sign"
              description="Print your contract and gather customer signatures in-person.">
            </CardSelectionCardStyle>
          </CardSelectionGroupStyle>
          {(displayEmails) && <VerifyEmails deliveryGroup={deliveryGroup} />}
          <ContractError>{error}</ContractError>
          <SigningOptionsButton
            aria-label="next"
            text={displaySendContract ? "Send Contract" : "Next"}
            variant="primary"
            allytmln={displaySendContract ? "send contract" : "next"}
            onClick={handleNext}
            loading={isSigningOptionLoading}
          />
          <EditContractModal />
        </div>
      </Container>
    </>
  );
};

export default SigningOptions;
