import { Box } from "@ally/metronome-ui";
import {
  SubHeaderStyle,
} from "./DeliveryGroupHeaders/DeliveryGroupStyle";
import { useParams } from "react-router";
import { useGetDeliveryGroups } from "../../../hooks/deliveryGroupHooks/useGetDeliveryGroups";
import { useDealershipInfo } from "../../../hooks/useDealershipInfo";
import { useEffect } from "react";
import { TextHeadingStyled } from "../DeliveryGroupTabControlerStyled";
import DeliveryGroupDropDown from "./DeliveryGroupHeaders/DeliveryGroupDropDown";
import { useAnalytics } from "../../../hooks/analytics/useAnalytics";
import { PageViews, emitPageViewEvent } from "../../../analytics/pageViews";

type Props = {
  isActiveTab: boolean;
  numberOfOpenGroups: number;
};
const NoDeliveryGroupSelected: React.FC<Props> = ({ isActiveTab, numberOfOpenGroups }) => {
  const analytics = useAnalytics();
  const [dealershipInfo] = useDealershipInfo();
  const dealershipNumber = dealershipInfo?.dealershipNumber;
  const { id } = useParams() as any;
  const { isLoading, isError, error } = useGetDeliveryGroups(
    dealershipNumber,
    id
  );

  useEffect(() => {
    if (isActiveTab) {
      analytics(emitPageViewEvent, PageViews.DELIVERY_GROUP_DASHBOARD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveTab]);

  if (isLoading) {
    return <></>;
  }
  if (isError) {
    // handle error display
    return <>{error}</>;
  }
  let subheader;
  if (numberOfOpenGroups === 0) {
    subheader = <>You can create a group to start adding vehicles for your multi-vehicle contract.</>
  } else {
    subheader = <>You can create multi-vehicle contracts by adding vehicles from the vehicles list to your delivery groups. To create your contract, finalize the purchase terms of each vehicle in your delivery group and update your Deal Status to Ready for Contract. Once you’ve finalized your delivery group, select < b > Create Contract </b > to view and sign your documents.</>
  }
  return (
    <>
      <TextHeadingStyled
        tag="h2"
        size="md"
        tabIndex={-1}
      >
        Delivery group
      </TextHeadingStyled>
      <Box>
        <SubHeaderStyle>
          {subheader}
        </SubHeaderStyle>
        <DeliveryGroupDropDown type="ALL" />
      </Box>
    </>
  );
};

export default NoDeliveryGroupSelected;
