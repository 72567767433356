import { Button, ButtonGroup, DocUpload, Modal } from "@ally/metronome-ui";
import styled from "styled-components";

export const OuterContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;
    h2 {
        line-height: 28px;
        font-size: 20px;
    }
    h4 {
        line-height: 24px;
        margin-left: 4px;
    }
    p {
        line-height: 20px
    }
`
export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: #DDDDDD;
    margin-top: 4px;
`
export const TitleAndDescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`
export const PreviewButton = styled(Button)`
    width: 108px;
    height: 44px;
`
export const DocUploadStyled = styled(DocUpload)`
   label {
    height: 34px;
    width: 122px;
   }
`
export const ButtonGroupStyled = styled(ButtonGroup)`
    margin-left: 0px;
    margin-top: 64px;
`
export const ErrorMessageWrapper = styled.p`
    font-weight: bold;
    font-size: 14px;
    color: #D63440;
    line-height: 20px;
    margin-top: 24px;

`

export const SubmitForFundingModalStyled = styled(Modal)`
  width: 342px;
  height: 281px;

  div > h1 {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 16px;
  }
  
  p {
    font-size: 16px;
    line-height: 24px;
    margin: 10px 8px 22px 0;
  }
`;


export const ModalButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;


export const CancelButtonStyle = styled(Button)`
  min-width: 143px;
  color: #0071c4;
  background-color: #e7f6fd;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
`;

export const ContinueButtonStyle = styled(Button)`
  width: 143px;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: 16px;
`;

export const ModalContentContainer = styled.div`
  
`
