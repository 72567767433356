import { useContext, useState } from "react";
import { ContractProgressTracker } from "../../ProgressTracker/ContractProgressTracker";
import ContractedDeliveryGroupDetailsNew from "./DeliveryGroupDetails/ContractedDeliveryGroupDetailsNew";
import DeliveryGroupHeader from "./DeliveryGroupHeaders/DeliveryGroupHeader";
import { ContextType, TabContext } from "../TabContext";
import {
    ButtonGroupStyled,
  Divider,
  DocUploadStyled,
    ErrorMessageWrapper,
  OuterContainer,
    PreviewButton,
  TitleAndDescriptionWrapper,
} from "./SendContractStyled";
import { ChangeSigningOptionsModal } from "../../Modal/ChangeSigningOptionsModal";
import { useGetDocuSignDownloadUrl } from "../../../hooks/docusignHooks/useGetDocusignDownloadUrl";
import { SubmitForFundingModal } from "../../Modal/SendContractModals";

type SendContractProps = {
  isActiveTab: boolean;
  dealershipNumber: string;
  deliveryGroupId: string;
  setHideRecontractForDG: (string: string) => void;
};

const SendContract: React.FC<SendContractProps> = ({
  dealershipNumber,
  deliveryGroupId,
  setHideRecontractForDG,
}) => {
  const progressTracker = <ContractProgressTracker stepNumber={4} />;
    const { saveActiveDeliveryGroupName } = useContext(
    TabContext
    ) as ContextType;
  const { data: docusignContractUrls, isLoading } = useGetDocuSignDownloadUrl(
    dealershipNumber,
    deliveryGroupId
  );
  const [fileTypeError, setFileTypeError] = useState(null);
  const [attachedFiles, setAttachedFiles] = useState([]);

  return (
    <>
      <DeliveryGroupHeader />
      <ContractedDeliveryGroupDetailsNew
        progressTracker={progressTracker}
        updateDeliveryGroupName={saveActiveDeliveryGroupName}
        deliveryGroupStatus={"CONTRACT_FINALIZED"}
      />
      <Divider />
      <OuterContainer>
        <h2>Gather documents to submit for funding</h2>
        <TitleAndDescriptionWrapper>
          <h4>1. Signed DocuSign contract package</h4>
          <p>
            Review a compilation of DocuSign documents with signatures,
            including your contract.
          </p>
        </TitleAndDescriptionWrapper>
        <PreviewButton
          text={isLoading ? "Processing" : "Preview"}
          loading={isLoading}
          variant="secondary"
          ariaLabel="View signed DocuSign contract package (PDF)"
          ariaLiveLoading="Processing signed DocuSign contract package (PDF)"
          onClick={() => {
            if (docusignContractUrls) {
              window.open(docusignContractUrls.view, "_blank");
            }
          }}
        />
        <TitleAndDescriptionWrapper>
          <h4>2. Additional documents for funding (optional)</h4>
          <div>
            <p>
              Upload additional required documents such as invoice, book-out
              sheet and stipulations.
            </p>
            <p>
              We only accept <b>JPEG, JPG, PNG, PDF</b> and <b>HEIC</b> files
              up to <b>20MB</b> total. Note that you won't be able to preview HEIC files.
            </p>
          </div>
        </TitleAndDescriptionWrapper>
        <DocUploadStyled
          onChange={(files: File[]) => {
            setAttachedFiles(files);
            setFileTypeError(null);
          }}
          accept=".heic,image/heic,.heif,image/heic,.jpeg,image/jpeg,.jpg,image/jpg,.png,image/png,.pdf,application/pdf"
          uploadFileButtonLabel="Upload File"
          uploadAdditionalFileButtonLabel="Upload File"
          maxTotalFileSizeMb={20}
          ariaLabelDocList="Additional documents to be sent for funding."
          getAriaLiveRemoveFile={(fileName: string) => `${fileName} removed`}
        />
      </OuterContainer>
      {fileTypeError ? (
        <ErrorMessageWrapper>{fileTypeError}</ErrorMessageWrapper>
      ) : null}
      <ButtonGroupStyled>
              <SubmitForFundingModal
                  dealershipNumber={dealershipNumber}
                  deliveryGroupId={deliveryGroupId}
                  setFileTypeError={setFileTypeError}
                  setHideRecontractForDG={setHideRecontractForDG}
                  attachedFiles={attachedFiles}
        />
        <ChangeSigningOptionsModal buttonType="secondary" />
      </ButtonGroupStyled>
    </>
  );
};

export default SendContract;
