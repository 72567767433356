import { Button, TextBody, TextHeading, useModalContext } from "@ally/metronome-ui";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { CancelButtonStyle, ContinueButtonStyle, ModalButtonContainer, ModalContentContainer, SubmitForFundingModalStyled } from "../Tab/DeliveryGroupTabViews/SendContractStyled";
import { useFundContract } from "../../hooks/contractHooks/useFundContract";
import { useUploadStagedFiles } from "../../hooks/contractHooks/useUploadStagedFiles";
import { useSubmitStagedFiles } from "../../hooks/contractHooks/useSubmitStagedFiles";
import { useGetFundedDocuments } from "../../hooks/contractHooks/useGetFundedDocuments";

const SubmitModalContent: React.FC = () => {
    return (
        <>
            <TextHeading size="sm" tag="h1" tabIndex={-1}>
                You're submitting your documents for funding.
            </TextHeading>
            <TextBody size="sm" tag="span" tabIndex={0}>
                <p>
                    Make sure you upload all required documents. Once you submit, you
                    won't be able to submit any other documents with this transaction.
                </p>
            </TextBody>
        </>
    );
};

const ProcessingModalContent: React.FC = () => {
    return (
        <>
            <TextHeading size="sm" tag="h1" tabIndex={-1}>
                You're uploading your <br></br> documents for funding.
            </TextHeading>
            <TextBody size="sm" tag="span" tabIndex={0}>
                <p>
                    This might take a moment. Stay on this screen to finish processing
                    your documents. Make sure you don't refresh your screen.
                </p>
            </TextBody>
        </>
    );
};


const SendForFundingModalTrigger: React.FC<{
    setFileTypeError: React.Dispatch<React.SetStateAction<string | null>>;
    attachedFiles: File[];
}> = ({
    setFileTypeError,
    attachedFiles
}) => {
    const { setOpen } = useModalContext();

    const acceptedFileTypes = [
        ".heic",
        ".heif",
        ".jpeg",
        ".jpg",
        ".png",
        ".pdf",
    ];

    const validateFileType = async (files: File[]) => {
        const filesNotSupported = files.filter(
            (file) => {
                const fileTypeValid = acceptedFileTypes.some(type => file.name.toLowerCase().endsWith(type.toLowerCase()));
                return !fileTypeValid;
            }
        );
        if (filesNotSupported.length > 0) {
            setFileTypeError("Upload a supported file type to continue.");
            return true;
        } else {
            setFileTypeError(null);
            return false;
        }
    };

    const handleContinueClick = async () => {
        const error = await validateFileType(attachedFiles);
        if (error) {
            return;
        } else {
            setOpen(true);
        }
    }

    return (
        <>
            <Button
                variant="primary"
                text="Continue"
                onClick={handleContinueClick}
            />
        </>
    );
};

type SubmitForFundingModalContentProps = {
    closeModal?: () => void;
    dealershipNumber: string;
    deliveryGroupId: string;
    setFileTypeError: React.Dispatch<React.SetStateAction<string | null>>;
    setHideRecontractForDG: React.Dispatch<React.SetStateAction<string>>;
    attachedFiles: File[];
};

const SendForFundingModalContent: React.FC<
    SubmitForFundingModalContentProps
> = ({ dealershipNumber, deliveryGroupId, setFileTypeError, setHideRecontractForDG, attachedFiles }) => {
    const { setOpen } = useModalContext();
    const fundContract = useFundContract(dealershipNumber);
    const uploadFiles = useUploadStagedFiles(dealershipNumber, deliveryGroupId);
    const submitStagedFiles = useSubmitStagedFiles(dealershipNumber, deliveryGroupId);
    const { data: documents } = useGetFundedDocuments(dealershipNumber, deliveryGroupId);
    const [showProcessing, setShowProcessing] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [cancelClicked, setCancelClicked] = useState(false)
    const controllerRef = useRef<AbortController>(null);

    const { id: customerId } = useParams() as any;

    const onSuccess = () => {
        setLoadingProgress(100);
    };

    const onError = (errs: string[]) => {
        errs.map((err) => console.warn(err))
    };

    //Polls for status of documents and will fund when status is success and cancel has not been clicked.
    useEffect(() => {
        if (documents?.status === "PENDING") {
            setLoadingProgress(80)
        }
        if (documents?.status === 'SUCCESS' && !cancelClicked) {
            fundContract.mutate({
                deliveryGroupId: deliveryGroupId!,
                customerId: customerId,
                onSuccess,
                onError
            }) 
        }
    }, [documents?.status, cancelClicked, controllerRef?.current?.signal])

    //Combine files into one pdf and put in submitted_documents/ 
    const onStagedFileSuccess = useCallback(async () => {
        setLoadingProgress(60);
        if (controllerRef.current.signal.aborted || cancelClicked) return;
        try {
            await submitStagedFiles.mutateAsync({
                signal: controllerRef.current?.signal
            });
        } catch (error) {
            //Only time this will error is if user has cancelled when submitStagedFiles.mutate() is called already and tries to submit again.
            return;
        }
        if (controllerRef.current.signal.aborted || cancelClicked) return;
    }, [controllerRef?.current?.signal])

    const stageAllFiles = useCallback(async (files: File[], signal: AbortSignal) => {
        for (const file of files) {
            if (signal.aborted || cancelClicked) return;
            await uploadFiles.mutateAsync({
                signal,
                file
            });
            setLoadingProgress(40);
            if (signal.aborted || cancelClicked) return;
        }
        if (signal.aborted || cancelClicked) return;
        onStagedFileSuccess()
    }, [controllerRef?.current?.signal])

    //Start process, send files to doc-service to save in S3 under staged_files/
    const fundContractAction = async (files: File[]) => {
        setCancelClicked(false);
        controllerRef.current = new AbortController();
        let signal = controllerRef.current.signal;
        if (signal.aborted || cancelClicked) {
            return;
        }
        setLoadingProgress(20);
        await stageAllFiles(files, signal);
        setHideRecontractForDG(deliveryGroupId);

    };

    const cancelAction = () => {
        setCancelClicked(true);
        controllerRef.current.abort()
        setOpen(false);

        controllerRef.current = new AbortController();
    }

    return (
        <ModalContentContainer>
            {!showProcessing && <SubmitModalContent />}
            {showProcessing && <ProcessingModalContent />}
            <ModalButtonContainer>
                {!showProcessing && (
                    <>
                        <ContinueButtonStyle
                            aria-label="continue"
                            text="Submit"
                            variant="primary"
                            allytmln="confirm-cancel-group-action"
                            onClick={() => {
                                setShowProcessing(true);
                                fundContractAction(attachedFiles);
                            }}
                        />
                        <CancelButtonStyle
                            aria-label="Go Back"
                            allytmln="stop-cancel-group-action"
                            text="Cancel"
                            variant="secondary"
                            onClick={() => setOpen(false)}
                        />
                    </>
                )}
                {showProcessing && (
                    <>
                        <ContinueButtonStyle
                            aria-label="continue"
                            text="Uploading..."
                            variant="primary"
                            allytmln="confirm-cancel-group-action"
                            loading={loadingProgress}
                        />
                        <CancelButtonStyle
                            aria-label="Go Back"
                            allytmln="stop-cancel-group-action"
                            text="Cancel"
                            variant="secondary"
                            onClick={cancelAction}
                        />
                    </>
                )}
            </ModalButtonContainer>
        </ModalContentContainer>
    );
};


export const SubmitForFundingModal = ({ dealershipNumber, deliveryGroupId, setFileTypeError, setHideRecontractForDG, attachedFiles }) => {

    return (<SubmitForFundingModalStyled
        hideCloseButton={true}
        trigger={<SendForFundingModalTrigger setFileTypeError={setFileTypeError} attachedFiles={attachedFiles} />}
        disableCloseOnOverlayClick={true}
        content={
            <SendForFundingModalContent
                dealershipNumber={dealershipNumber}
                deliveryGroupId={deliveryGroupId}
                setFileTypeError={setFileTypeError}
                setHideRecontractForDG={setHideRecontractForDG}
                attachedFiles={attachedFiles}
            />
        }
    />)
}

