import { useOktaAuth } from '@okta/okta-react';
import { atom, useAtom } from 'jotai';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect } from "react";
import { LogRocket } from '../logrocket';
import { useGetDealerships } from './dealershipHooks/useGetDealerships';

/*
Using Jotai to expose singleton useState object w/ local storage to perssist. Should default to PDN for user. 
Acceptable since token needed to access app
*/
type DealershipInfo = {
  dealershipName: string,
  dealershipNumber: string,
  dealershipState: string,
  dealershipAddress: string,
  dealershipCity: string,
  dealershipZip: string
}

const localStorageDealershipInfoBacker = () => {
  function setSelectedDealershipInfo(dealershipInfo: DealershipInfo) {
    sessionStorage.setItem("selected-dealership", JSON.stringify(dealershipInfo));
  }

  function getSelectedDealershipInfo() {
    const res = sessionStorage.getItem("selected-dealership");
    if (res) {
      return JSON.parse(res) as DealershipInfo;
    }
    return null;
  }

  return { setSelectedDealershipInfo, getSelectedDealershipInfo };
}

const { getSelectedDealershipInfo, setSelectedDealershipInfo } = localStorageDealershipInfoBacker();
const selectedDealershipInfo = atom(getSelectedDealershipInfo());

function identifyWithLaunchDarkly(ldClient: any, userId: string, dealershipNumber: string, dealershipState: string): void {
  const context = {
    kind: "multi",
    user: {
      key: userId,
      anonymous: false
    },
    organization: {
      key: dealershipNumber,
      address: {
        state: dealershipState
      }
    }
  }

  ldClient.identify(context);
}

export function useDealershipInfo(): [DealershipInfo, (v?: DealershipInfo) => void]{
  const secureRoutesDisabled = process.env.REACT_APP_SECURE_ROUTES !== 'true';
  const [state, setState] = useAtom(selectedDealershipInfo);
  const { authState, oktaAuth } = useOktaAuth();
  const ldClient = useLDClient();
  const { data: dealerships, isLoading } = useGetDealerships();

  oktaAuth.authStateManager.subscribe((authState) => {
    if (authState?.isAuthenticated && state !== null) {
      LogRocket.identify(authState.accessToken?.claims?.uid, {
        name: state.dealershipName,
        dealershipName: state.dealershipName,
        dealershipNumber: state.dealershipNumber,
        state: state.dealershipState,
      });

      identifyWithLaunchDarkly(ldClient,
        authState.accessToken?.claims?.login.toString(),
        state.dealershipNumber,
        state.dealershipState);
    }
  });

  const wrapSet = useCallback((v?: DealershipInfo ) => { 
    if(v && v.dealershipNumber !== state?.dealershipNumber){
      setSelectedDealershipInfo(v);

      if (authState?.isAuthenticated) {
        LogRocket.identify(authState.accessToken?.claims?.uid, {
          name: v.dealershipName,
          dealershipName: v.dealershipName,
          dealershipNumber: v.dealershipNumber,
          state: v.dealershipState,
        });

        identifyWithLaunchDarkly(ldClient,
          authState.accessToken?.claims?.login.toString(),
          v.dealershipNumber,
          v.dealershipState);
      }

      return setState((curr) => {
        return {
        ...curr,
        ...v
        }
      });
    }
  }, [ldClient, state, authState, setState]);

  useEffect(() => {
    if(state === null && !isLoading && (authState?.accessToken?.claims || secureRoutesDisabled) ){
      
      const pdn = authState?.accessToken?.claims?.primaryDealership?.toString();
      const primaryDealership = dealerships.find(dealership => dealership.dealershipNumber === pdn);
      const firstAvailableDealership = dealerships[0];
      const dealership = primaryDealership ?? firstAvailableDealership;
      const dealershipNumber = dealership?.dealershipNumber ?? "";
      const dealershipName = dealership?.name ?? "Unknown";
      const dealershipState = dealership?.state ?? "Unknown";
      const dealershipAddress = dealership?.address ?? "";
      const dealershipCity = dealership?.city ?? "";
      const dealershipZip = dealership?.zip ?? "";
       
      wrapSet({
        dealershipNumber,
        dealershipState,
        dealershipName,
        dealershipAddress,
        dealershipCity,
        dealershipZip
      } as DealershipInfo);
    }
  }, [state, authState, isLoading, dealerships, wrapSet, setState, secureRoutesDisabled]);

  return [state as DealershipInfo, wrapSet as (v?: DealershipInfo) => void];
}
