const setupLogRocket = require('logrocket/setup');

const logrocketEnv = process.env.REACT_APP_LOGROCKET;

const LogRocket = setupLogRocket({
  sdkServer: 'https://cdn.lr-in-prod.com',
  ingestServer: 'https://r.lr-in-prod.com',
});

LogRocket.init(logrocketEnv, {
  network: {
    isEnabled: false
  }
});

export { LogRocket };
