import React from "react";
import { muiAdd } from "@ally/metronome-icons/dist/cjs";
import { Spinner } from "@ally/metronome-ui";
import { useContext, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router";
import { useAssetContractVehicles } from "../../../../hooks/assetContractHooks/useGetAssetContractVehicles";
import { useGetDeliveryGroup } from "../../../../hooks/deliveryGroupHooks/useGetDeliveryGroup";
import { useDealershipInfo } from "../../../../hooks/useDealershipInfo";
import {
  convertDeliveryGroupWithoutContractToTableData,
  convertVehiclesToTableData,
} from "../../../../utils/table-utils";
import { VehicleDashboardDeliveryGroup } from "../../../Dashboards/SingleCustomerDashboard/VehicleDashboard/VehicleDashboardDeliveryGroup";
import { TabContext, ContextType } from "../../TabContext";
import {
  ActionBarContainerStyle,
  AddVehicleButtonStyled,
  DeliveryGroupDetailTitle,
  DetailContainer,
  DetailRowContent,
  DetailRowContentName,
  DetailRowHeader,
  EditButtonStyled,
  OutContainer,
  StyledIcon,
  AddVehicleDiv,
  SubHeaderStyle,
  StyledAlert,
  InProgressError,
  AlertContainer,
} from "./DeliveryGroupDetailsStyled";
import { ActiveTabs } from "../../../../constants/enums";
import { formatNumberToCurrency } from "../../../../utils/currency";
import InsurancePolicy from "../InsurancePolicy";

type DgProps = {
  actionModal?: any; //idk how to type this correctly
  progressTracker: JSX.Element;
  updateDeliveryGroupName: (deliveryGroupName: string) => void;
  deliveryGroupStatus: "OPEN" | "READY_FOR_CONTRACT";
  error?: string;
  sortTable?: boolean;
  setShowContractModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeliveryGroupDetailsWithAssets = ({
  sortTable,
  error,
  actionModal,
  progressTracker,
  updateDeliveryGroupName,
  deliveryGroupStatus,
  setShowContractModal }: DgProps) => {
  const displayAlertRef = useRef(null);
  const [dealershipInfo] = useDealershipInfo();
  const dealershipNumber = dealershipInfo?.dealershipNumber;
  const { id } = useParams() as any;
  const history = useHistory();

  const {
    saveSelection,
    displaySuccessfulAction,
    saveDisplaySuccessfulAction,
    activeDeliveryGroupId } = useContext(TabContext) as ContextType;

  const {
    isError: isVehicleError,
    isSuccess: isVehicleSuccess,
    isLoading: isVehicleLoading,
    data: vehicles,
    error: vehicleError,
  } = useAssetContractVehicles(dealershipNumber, id, activeDeliveryGroupId);
  const {
    isSuccess: isDeliveryGroupSuccess,
    isLoading: isDeliveryGroupLoading,
    data: deliveryGroup,
  } = useGetDeliveryGroup(dealershipNumber, activeDeliveryGroupId);

  // sync state with DeliveryGroup component
  //TODO: idk if this is even needed/used anymore
  useEffect(() => {
    if (deliveryGroup?.group_name) {
      updateDeliveryGroupName(deliveryGroup.group_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryGroup?.group_name, error])

  const totalAmountFinanced = (vehicles ?? []).reduce((acc, curr) => acc + (curr.amountFinanced), 0);

  const editGroup = () => {
    const params = new URLSearchParams();
    params.append("action", "edit-group");
    history.push({ search: params.toString() });
    saveSelection({
      destinationTab: ActiveTabs.DELIVERY_GROUPS
    });
  };

  const handleAddVehicle: () => void = () => {
    const t = document.querySelectorAll('[role="tablist"]')[0].childNodes[0];
    if (t) {
      (t as HTMLElement).click();
    }
  };

  let vehicleDash = undefined;
  if (isDeliveryGroupSuccess && deliveryGroup) {
    const tableDeliveryGroup = convertDeliveryGroupWithoutContractToTableData(deliveryGroup, deliveryGroupStatus);
    if (isVehicleSuccess || isVehicleLoading) {
      const tableVehicles = vehicles
        ? convertVehiclesToTableData(vehicles)
        : [];
      vehicleDash = (
        <VehicleDashboardDeliveryGroup
          vehicles={tableVehicles}
          isVehiclesError={isVehicleError}
          isVehiclesSuccess={isVehicleSuccess}
          isVehiclesLoading={isVehicleLoading}
          vehiclesError={vehicleError}
          showStickyNav={true}
          sortTable={sortTable}
        />
      );
    }

    const convertDateToTwoDigitString = (date?: string): string => {
      const newDate = date?.split("T")[0]
      if (!date) {
        return "";
      }
      const [year, month, day] = newDate!.split('-');
      const result = `${month}/${day}/${year}`;
      return result;
    }
    return (
      <AlertContainer>
        {displaySuccessfulAction && (
          <StyledAlert
            variant="success"
            cancellable
            ref={displayAlertRef}
            handleClose={() => saveDisplaySuccessfulAction(null)}
            body={<p
              tabIndex={0}
              aria-label={`Info Icon. You successfully ${displaySuccessfulAction}.`}>
              {`You successfully ${displaySuccessfulAction}.`}
            </p>}
            focusOnMount={true}
          />
        )}
        {progressTracker}
        <DeliveryGroupDetailTitle>
          Delivery group details
        </DeliveryGroupDetailTitle>
        <SubHeaderStyle>
          If you have a guarantor or co-buyer, you must add their information to
          each vehicle in your group to create a contract. To copy contract
          detail to multiple vehicles, use the clone feature on any vehicle ready
          for contract. All deal statuses must be ready for contract to create
          your multi vehicle contract.
        </SubHeaderStyle>

        <OutContainer>
          <DetailContainer>
            <div>
              <DetailRowHeader>Group name</DetailRowHeader>
              <DetailRowContentName>
                {tableDeliveryGroup.group_name}
              </DetailRowContentName>
            </div>
            <div>
              <DetailRowHeader>Delivery status</DetailRowHeader>
              <DetailRowContent>
                {tableDeliveryGroup.delivery_status}
              </DetailRowContent>
            </div>
            <div>
              <DetailRowHeader>Contract date</DetailRowHeader>
              <DetailRowContent>
                {convertDateToTwoDigitString(tableDeliveryGroup.contract_date)}
              </DetailRowContent>
            </div>
            <div>
              <DetailRowHeader>Total number of vehicles</DetailRowHeader>
              <DetailRowContent>{vehicles?.length ?? 0}</DetailRowContent>
            </div>
            <div>
              <DetailRowHeader>Total selling price</DetailRowHeader>
              <DetailRowContent>{`${formatNumberToCurrency(totalAmountFinanced)}`}</DetailRowContent>
            </div>
          </DetailContainer>
          <InsurancePolicy
            dealershipNumber={dealershipNumber}
            customerId={id}
            deliveryGroupId={activeDeliveryGroupId}
            setShowContractModal={setShowContractModal}
          /> 
          <ActionBarContainerStyle disableStackSmDown={true}>
            {actionModal}
            <EditButtonStyled
              variant="primary"
              allytmln="edit-group"
              text="Edit group"
              onClick={editGroup}
            />
            <AddVehicleDiv onClick={handleAddVehicle}>
              <StyledIcon
                icon={muiAdd}
                size="lg"
                ariaHidden
              />
              <AddVehicleButtonStyled
                aria-label="Add vehicles"
                allytmln="add-vehicles"
                variant="link"
                text="Add vehicles"
              />
            </AddVehicleDiv>

          </ActionBarContainerStyle>
        </OutContainer>
        <InProgressError>{error}</InProgressError>
        {vehicleDash}
      </AlertContainer>
    );
  } else if (isDeliveryGroupLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  } else {
    return <></>;
    //TODO: onloading and/or errors
  }
};

export default DeliveryGroupDetailsWithAssets;
