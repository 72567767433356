import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useHttp } from "../adaptorHooks/useHttp";
import { getDocumentsStatusKey } from "../../utils/react-query-key-factory";

async function submitStagedFiles(put: <In, Out>(url: string, body: In) => Promise<Out | null>, dealershipNumber: string, deliveryGroupId: string, signal: AbortSignal) {
    try {
        const endpoint = `dealership/${dealershipNumber}/delivery-groups/${deliveryGroupId}/submit-staged-files`;
        return put<any, void>(endpoint, { signal: signal });
    } catch (error) {
        console.log('error in submitting staged files: ', error)
    }
}
type SubmitFiles = {
    signal: AbortSignal;
}

export function useSubmitStagedFiles(dealershipNumber: string, deliveryGroupId: string) {
    const { put } = useHttp();
    const queryClient = useQueryClient();

    return useMutation(
        ['submit-staged-documents'],
        async (req: SubmitFiles) => {
            await submitStagedFiles(put, dealershipNumber, deliveryGroupId, req.signal)
        },
        {
            onSettled: (data, req: SubmitFiles) => {
                queryClient.invalidateQueries(getDocumentsStatusKey(dealershipNumber, deliveryGroupId),
                )
            },
            onError: (error) => {
                console.log('error in submitting staged files: ', error)
            }
        }
    )
}
